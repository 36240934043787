<template>
  <div class="d-flex flex-column justify-items-center">
    <img class="mb-4 mx-auto" src="../../assets/logo.svg" alt="Toast Lettings" width="150">
    <b-card>
      <b-form @submit.prevent="onSubmit">
        <b-form-group label="Email Address" label-for="email" :invalid-feedback="getValidationMessage('email')" :state="!getValidationMessage('email')">
          <b-form-input :disabled="loading" id="email" required type="email" v-model="email"></b-form-input>
        </b-form-group>
        <div class="d-flex flex-row w-100">
          <div class="flex-fill"></div>
          <b-button class="ml-2" variant="link" @click="$router.push('/auth/login')">Log In?</b-button>
          <b-button class="ml-2" type="submit" variant="primary"><b-spinner class="mx-3" small variant="light" v-if="loading" /><span v-if="!loading">Reset Password</span></b-button>
        </div>
      </b-form>
    </b-card>
  </div>
</template>

<script>
import handleErrors from '../../mixins/handleErrors'
import { mapActions, mapState } from 'vuex'

export default {
  mixins: [handleErrors],
  data() {
    return {
      email: ''
    };
  },
  computed: {
    ...mapState('auth', ['loading', 'user']),
  },
  methods: {
    ...mapActions('auth', ['check', 'login', 'forgot']),
    onSubmit() {
      this.forgot({email: this.email})
    }
  }
}
</script>

<style>

</style>
